import React from 'react';
import { graphql } from 'gatsby';
import CustomPageWrapper from '../components/CustomPageWrapper/CustomPageWrapper';
import LifeAtSmarttie from '../sections/life-at-smarttie';
import Helmet from '../components/Helmet';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const Careers = () => {
  const { t } = useTranslation();
  const title = t('metadata.careers.title');

  const description = t('metadata.careers.description');

  const banner = true;

  const url = 'https://www.smarttie.ca/careers/';

  const data = {
    title,
    description,
    banner,
    url,
  };

  return (
    <>
      <Helmet data={data} />

      <CustomPageWrapper withNavbar={true}>
        <LifeAtSmarttie />
      </CustomPageWrapper>
    </>
  );
};

export default Careers;

// export const Head = () => {
//   const title = 'Join Smarttie: Boost Your Career';

//   const description =
//     'Explore opportunities at Smarttie and transform the future with us. Discover how your talent can contribute to innovation and global impact.';

//   const banner = true;

//   const url = 'https://www.smarttie.ca/careers/';

//   const data = {
//     title,
//     description,
//     banner,
//     url,
//   };

//   return (
//     <>
//       <Helmet data={data} />
//     </>
//   );
// };

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
